<template>
  <div class="weap">

    <div class="left">

      <span>Copyright ©2023</span>

      <span>中科无线电（广东）有限公司</span>

      <span>版权所有</span>

    </div>

    <div class="right">

      <span></span>
      <span><a href="#"><!-- xxx --></a></span>

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">

</style>
