import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    searchtxt:'',
    tobakcname:'',
    tobackid:'',
    issearch:false
  },
  getters: {
    searchtxt:(state=>{return state.searchtxt}),
    issearch:(state=>{return state.issearch})
  },
  mutations: {
    settxt(state, newval) {
      state.searchtxt = newval
    },
    SETID(state, newval) {
      state.tobackid = newval
    },
    SETNAME(state, newval) {
      state.tobakcname = newval
    },
    SET_SEARCH(state, newval) {
      state.issearch = newval
    },
  },
  actions: {

  },
  modules: {

  }
})
