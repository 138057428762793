<template>
  <div id="app">
    <!-- elui -->
    <el-container style="min-height: 100vh">
      <!-- 固定头 -->
      <Navigation></Navigation>
      
      <router-view />
      <!-- 固定脚 -->
      <el-footer style="padding: 20px;margin-top: auto;">
        <Footer></Footer>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import Navigation from '@/components/homeHeader/navigation.vue';
import Footer from '@/components/homeHeader/footer.vue'
export default {
components:{Navigation,Footer}
}
</script>
<style lang="scss">
* {
  margin: 0px;
  padding: 0px;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #666;
}

body {
  padding: 0px;
  margin: 0px;
  color: #666666;
  font-size: 1.2rem;
  background-color: #FFF;
  -webkit-overflow-scrolling: touch;
  font-family: Arial, "微软雅黑";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0px 0px;
  margin: 0px 0px;
  font-size: 1.4rem;
  font-weight: inherit;
}

ul,
li,
p {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
.el-footer {
  background-color: #131313;
  color: #fff;
  height: 100px; 
}
</style>
