import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  { path: '*', redirect: '/home' },
  {
    path: '/home',
    component: () => import('../views/home/home.vue')
  },
  /* 关于中科 */
  {
    path: '/about',
    component: () => import('../views/about/about.vue'),
  },
  {
    path: '/careers',
    component: () => import('../views/about/careers')
  }, {
    path: '/memorabilia',
    component: () => import('../views/about/memorabilia')
  },
  {
    path: '/jiaruzhangjing/:id',
    component: () => import('../views/about/careers/jiaruzhangjing.vue')
  },
  /* 产品中心 */  
  {
    path: '/erjiguan/:id/',
    component: () => import('../views/erjiguan/erjiguan.vue')
  },
  {
    path: '/screen/:name',
    component: () => import('../components/Screen.vue')
  },
  /* 联系我们 */
  {
    path: '/contact',
    component: () => import('../views/contact/contact.vue')
  },
  /* 新闻 */
  {
    path: '/news',
    component: () => import('../views/news/news.vue')
  },
  {
    path: '/newdetails/:id',
    component: () => import('../views/news/newDetails.vue')
  },
  /* 应用 */
  {
    path: '/field',
    component: () => import('../views/field/index.vue')
  },
  {
    path: '/applist/:id',
    component: () => import('../views/field/appslist.vue')
  },
  {
    path: '/details/:id',
    component: () => import('../views/field/details.vue')
  },
  /* 搜索 */
  {
    path: '/search',
    component: () => import('../views/search.vue')
  },
  /* 质量体系 */
  {
    path: '/zhiliangfangzhen/:id',
    component: () => import('../views/zhiliangfangzhen/zhiliangfangzhen.vue'),
  }, 
  {
    path: '/report_list/:id',
    component: () => import('../views/zhiliangfangzhen/report_list.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 } // 这里返回一个对象，将页面滚动到 (0, 0) 坐标，即页面最上面  
  }
})

export default router
